import "../styles/globals.scss";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import type { AppProps } from "next/app";
import React from "react";
import HeadSeo from "../components/HeadSeo";
import siteMetadata from "../lib/data/siteMetadata";
import {
  Hydrate,
  MutationCache,
  QueryClient,
  QueryClientProvider
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { notify } from "../utils/notify";
import Script from "next/script";

function BloccpayApp({ Component, pageProps }: AppProps) {
  const mutationCache = new MutationCache({
    onSuccess: (mutation: any) => {
      if (mutation?.message) {
        notify({
          type: "success",
          message: mutation?.message
        });
      }
    },
    onError: (error: any, mutation: any) => {
      if (error?.response?.data?.message) {
        notify({
          type: "error",
          message: error?.response?.data?.message
        });
      }
    }
  });

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 300000
          }
        },
        mutationCache
      })
  );

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id='${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}'`}
      />

      <Script strategy="lazyOnload" id="google-analytics">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
        `}
      </Script>

      <HeadSeo
        title={`${siteMetadata.companyName} `}
        description={siteMetadata.description}
        canonicalUrl={`${siteMetadata.siteUrl}`}
        ogImageUrl={siteMetadata.siteLogo}
        ogTwitterImage={siteMetadata.siteLogo}
        ogType={"website"}
      >
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
      </HeadSeo>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <Component {...pageProps} />
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <NotificationContainer />
    </>
  );
}

export default BloccpayApp;
