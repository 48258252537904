export const NIGERIA_CODE = "NG";

export const BLOCCPAY_URL = process.env.NEXT_PUBLIC_BLOCCPAY_URL;
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

export const FACEBOOK_URL = "https://www.facebook.com/bloccpay/";
export const TWITTER_URL = "https://twitter.com/bloccpay/";
export const INSTAGRAM_URL = "https://www.instagram.com/bloccpay/";
export const LINKEDIN_URL = "https://www.linkedin.com/company/bloccpay/";

export const PLAYSTORE_GOOGLE_AUTHENTICATOR_URL =
  "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en";
export const APPSTORE_GOOGLE_AUTHENTICATOR_URL =
  "https://apps.apple.com/us/app/google-authenticator/id388497605";

export const FEES = {
  CRYPTO_WITHDRAWAL: 1,
  FUNDING: 0,
  FIAT_FUNDING: 0,
  FIAT_WITHDRAWAL: 1
};

export const BASE_CURRENCY = "USD";

export const BASE_COIN = "USDT";

export const COINS = [
  {
    id: "1",
    label: "USDT",
    value: "USDT",
    name: "Tether",
    logo: "/assets/coins/tether-usdt-logo.svg",
    symbol: "USDT"
  },
  {
    id: "2",
    label: "USDC",
    value: "USDC",
    name: "USD Coin",
    logo: "/assets/coins/usd-coin-usdc-logo.svg",
    symbol: "USDC"
  },
  {
    id: "3",
    label: "DAI",
    value: "DAI",
    name: "Dai",
    logo: "/assets/coins/dai-logo.svg",
    symbol: "DAI"
  },
  {
    id: "4",
    label: "BUSD",
    value: "BUSD",
    name: "Binance USD",
    logo: "/assets/coins/binance-usd-busd-logo.svg",
    symbol: "BUSD"
  }
];

export const BLOCKCHAINS = [
  {
    id: "1",
    label: "ETH",
    value: "ETH",
    name: "Ethereum (ERC20)",
    logo: "/assets/blockchains/ethereum-eth-logo.png",
    symbol: "ETH"
  },
  {
    id: "2",
    label: "BSC",
    value: "BSC",
    name: "BNB Smart Chain (BEP20)",
    logo: "/assets/blockchains/binance-smart-chain-bsc-logo.png",
    symbol: "BSC"
  },
  {
    id: "3",
    label: "MATIC",
    value: "MATIC",
    name: "Polygon",
    logo: "/assets/blockchains/polygon-matic-logo.png",
    symbol: "MATIC"
  }
];

export const FILE_UNIT = {
  GB: 1073741824,
  MB: 1048576,
  KB: 1024
};
